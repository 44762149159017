/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import app from "gatsby-plugin-firebase-v9.0";
import { getAnalytics } from "firebase/analytics";

export const onClientEntry = () => {
  getAnalytics(app);

  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    /* eslint-disable-next-line */
    import(`intersection-observer`);
    console.log(`# IntersectionObserver is polyfilled!`);
  }
};
